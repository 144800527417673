import CoreTools from "../shared/services/CoreTools";
import Navigate from "../shared/services/Navigate";
import Server from "./Server";

const mi = [
    {
        name: "My Apps",
        link: "#MyApps",
        permissions: ["User"],
        sub: []
    },
    {
        name: "My Profile",
        link: "#MyProfile",
        permissions: ["User"],
        sub: [
            {
                name: "Active Sessions",
                link: "#ActiveSessions",
                permissions: ["User"],
                sub: []
            }
        ]
    },
    // {
    //     name: "Certificates",
    //     link: "#Certificates",
    //     permissions: ["User"],
    //     sub: []
    // },
    // {
    //     name: "Routing",
    //     link: "#Routing",
    //     permissions: ["Routes"],
    //     sub: []
    // },
    // {
    //     name: "Reports",
    //     link: "#Reports",
    //     permissions: ["User"],
    //     sub: []
    // },
    {
        name: "Apps",
        link: "#Apps",
        permissions: ["AppsAdmin"],
        sub: []
    },
    {
        name: "Users",
        link: "#Users",
        permissions: ["UsersAdmin", "UserAdmin"],
        sub: []
    },
    {
        name: "Core Settings",
        link: "#Settings",
        permissions: ["Admins"],
        sub: []
    }
];

const hasPermission = up => m => m.permissions.find(p => up.includes(p));
const firstLink = (m, up) => {
    const subs = m.sub.filter(hasPermission(up));
    return subs.length ? subs[0].link : "";
};

let menu = false;
const Menu = () => menu || (() => {
    const nav = Navigate();
    
    const pageWatch = [];

    const getPage = hash => {
        const permissions = Server.getSession().permissions;
        return (m => {
            if (m) {
                if (m.link === hash) {
                    return [m.link, firstLink(m, permissions), ""];
                } else {
                    return (s => {
                        if (s) {
                            if (s.link === hash) {
                                return [m.link, s.link, firstLink(s, permissions)];
                            } else {
                                return (t => [m.link, s.link, t ? t.link : ""])(s.sub.filter(hasPermission(permissions)).find(t => t.link === hash));
                            }
                        } else {
                            return [m.link, "", ""];
                        }
                    })(m.sub.filter(hasPermission(permissions)).find(s => s.link === hash || s.sub.find(t => t.link === hash)) || m.sub[0]);
                }
            } else {
                return ["", "", ""];
            }
        })(mi.filter(hasPermission(permissions)).find(m => m.link === hash || m.sub.find(s => s.link === hash || s.sub.find(t => t.link === hash))) || mi[0]);
    };

    menu = {
        watch: cb => {
            const watch = {cb: cb};
            pageWatch.push(watch);
            return () => CoreTools.remove(pageWatch, watch);
        },
        get: () => getPage(nav.get()),
        mainMenu: hash => {
            const permissions = Server.getSession().permissions;
            const current = menu.get(hash);
            return mi.filter(hasPermission(permissions)).map(m => [m.name, m.link, current[0] === m.link]);
        },
        subMenu: () => {
            const pageSet = getPage(nav.get());
            const permissions = Server.getSession().permissions;
            const m = mi.filter(hasPermission(permissions)).find(m => m.link === pageSet[0]);
            return m ? m.sub.filter(hasPermission(permissions)).map(s => [s.name, s.link, pageSet[1] === s.link]) : [];
        }
    };

    nav.watch(hash => {
        const pageSet = getPage(hash);
        pageWatch.forEach(w => w.cb(pageSet));
    });

    return menu;
})();

export default Menu;