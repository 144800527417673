import React from "react";
import ActiveSessions from "./ActiveSessions";
import Server from "../../services/Server";
import Menu from "../../services/Menu";
import Navigate from "../../shared/services/Navigate";
import CoreTools from "../../shared/services/CoreTools";

const MyProfile = () => {
    const nav = Navigate();
    
    const session = Server.getSession();
    const menu = Menu();
    const revokeSession = id => Server.User.logout(id);
    
    const [page, setPage] = React.useState(menu.get()[1]);
    React.useEffect(() => menu.watch(pageSet => setPage(pageSet[1])), [menu, setPage]);

    return <>
        <div className="container-lg mt-2 bg-white rounded-3 p-2">
            <div>
                <div className="float-end">
                    <button className="btn btn-sm btn-danger bg-gradient" onClick={() => revokeSession(null)}><i className="fa-solid fa-arrow-right-from-bracket"></i> Log Out</button>
                </div>
                <h3 className="text-primary">My Profile</h3>
            </div>
            <table>
                <tbody>
                    <tr><td className="text-end">Name:</td><td>{session.firstname} {session.lastname}</td></tr>
                    <tr><td className="text-end">E-mail Address:</td><td>{session.email}</td></tr>
                    <tr><td className="text-end">Mobile Phone:</td><td>{CoreTools.asPhone(session.phone)}</td></tr>
                    <tr><td className="text-end">Permissions:</td><td>{session.permissions.map(p => <span key={p} className="badge bg-secondary" style={{marginLeft: "5px"}}>{p}</span>)}</td></tr>
                </tbody>
            </table>
        </div>
        <div className="container-lg mt-2">
            <div className="btn-group menu-group">
                {menu.subMenu().map(m => <button type="button" key={m[1]} onClick={() => nav.set(m[1])} className={`btn btn-${m[2] ? "primary" : "secondary"} btn-sm bg-gradient`}>{m[0]}</button>)}
            </div>
        </div>
        {CoreTools.switch(
            [page === "#ActiveSessions", () => <ActiveSessions />]
        )}
    </>;
};
export default MyProfile;