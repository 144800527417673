import CoreTools from "./CoreTools";

let navigate = false;

const Navigate = () => navigate || (() => {

    let location = window.location.hash;
    const watchers = [];

    window.addEventListener("popstate", event => {  
        if (location !== window.location.hash) {
            location = window.location.hash;
            watchers.forEach(w => w.cb(location));
        }
    });

    navigate = {
        get: () => location,
        watch: cb => {
            const watch = {cb: cb};
            watchers.push(watch);
            return () => CoreTools.remove(watchers, watch);
        },
        set: (url, delay) => url && setTimeout(() => window.location.href = url, delay)
    };
    return navigate;
})();

export default Navigate;