import Notify from "../shared/directives/Notify";
import Api from "../shared/services/Api";
import CoreTools from "../shared/services/CoreTools";
import Navigate from "../shared/services/Navigate";

const cookieName = "cgauthsessionkey";

let apiHost = window.location.hostname === "localhost" ? (sessionStorage.getItem("apihost") || "localhost") : "";

const session = {
    key: CoreTools.memory.get(cookieName),
    valid: false,
    mfa: false,
    username: "",
    firstname: "",
    lastname: "",
    phone: "",
    permissions: []
};

const watchers = {
    assign: (arr, cb) => {const watch = {cb: cb}; arr.push(watch); return () => CoreTools.remove(arr, watch);},
    notify: (arr, data) => arr.filter(w => typeof(w.cb) === "function").forEach(w => w.cb(data)),
    apiHost: [],
    sessions: []
};

const api = (endpoint, obj, cb) => {
    CoreTools.log("API Request:", endpoint, obj);
    Api(endpoint.slice(0, 1) === "/" ? apiHost + endpoint : endpoint).headers({"sessionKey": session.key}).json(CoreTools.isObject(obj) ? obj : {}).fetch(results => {
        if (results.statusCode === 200 && CoreTools.isObject(results.data)) {
            CoreTools.log("API Response:", endpoint, results.data);
            if (results.data.navigate) {Array.isArray(results.data.navigate) ? Navigate().set(...results.data.navigate) : Navigate().set(results.data.navigate);}
            if (results.data.message) {Array.isArray(results.data.message) ? Notify.message(...results.data.message) : Notify.message(results.data.message, "info", 10);}
            if (results.data.console) {Array.isArray(results.data.console) ? console.log(...results.data.console) : console.log(results.data.console);}
            if (results.data.setSession && CoreTools.isObject(results.data.setSession)) {
                Object.keys(session).forEach(key => delete(session[key]));
                Object.assign(session, results.data.setSession);
                if (session.valid && session.mfa && !session.permissions.includes("User")) {session.permissions.push("User");}
                watchers.notify(watchers.sessions, CoreTools.clone(session));
                if (session.key) {CoreTools.memory.set(cookieName, session.key, 60);}
                if (session.valid && session.mfa && CoreTools.getQueryParam("app")) {
                    setTimeout(() => api("/appLogin/toSite", {appName: CoreTools.getQueryParam("app")}));
                }
            }
            if (typeof(cb) === "function") {cb(results.data);}
        } else {
            Notify.message(`API Request Failed\nURL: ${endpoint.includes("/") ? endpoint : apiHost + "/" + endpoint}`, "danger", 15);
            if (typeof(cb) === "function") {cb({success: false});}
        }
    });
};

const identity = () => apiHost !== "localhost" && api("/api/session", null, data => console.log("Session:", session));

identity();

const Server = {
    watchApiHost: cb => watchers.assign(watchers.apiHost, cb),
    watchSessionUpdate: cb => watchers.assign(watchers.sessions, cb),
    getApiHost: () => apiHost,
    getSession: () => CoreTools.clone(session),
    setMessage: (message, status, time) => Notify.message(message, status || "info", time || 5),
    test: values => api(values.apiRootPath + "/api/test", null, data => {
        if (data.success) {
            apiHost = values.apiRootPath;
            sessionStorage.setItem("apihost", apiHost);
            watchers.notify(watchers.apiHost, apiHost);
            identity();
        }
    }),
    Guest: {
        login: values => api("/api/Guest/login", values),
        requestPasswordReset: values => api("/api/Guest/requestPasswordReset", values),
        submitPasswordReset: values => api("/api/Guest/submitPasswordReset", values),
        submitMfa: values => api("/api/Guest/submitMfa", values),
        resendPin: () => api("/api/Guest/resendPin")
    },
    User: {
        myApps: cb => api("/api/User/myApps", null, data => data.success && cb(data.apps)),
        logout: (sessionId, cb) => api("/api/User/logout", {sessionId: sessionId}, data => typeof(cb) === "function" && cb(data)),
        activeSessions: cb => api("/api/User/activeSessions", null, data => data.success && cb(data.sessions)),
    },
    AppsAdmin: {
        listApps: cb => api("/api/AppsAdmin/listApps", null, data => data.success && cb(data.apps)),
        getAppById: (id, cb) => api("/api/AppsAdmin/getAppById", {id: id}, data => data.success && cb(data.app)),
        saveApp: (values, cb) => api("/api/AppsAdmin/saveApp", values, data => data.success && typeof(cb) === "function" && cb()),
        deleteApp: (values, cb) => api("/api/AppsAdmin/deleteApp", values, data => data.success && typeof(cb) === "function" && cb())
    },
    UsersAdmin: {
        listUsers: cb => api("/api/UsersAdmin/listUsers", null, data => data.success && cb(data.users)),
        saveUser: (values, cb) => api("/api/UsersAdmin/saveUser", values, data => data.success && typeof(cb) === "function" && cb()),
        deleteUser: (values, cb) => api("/api/UsersAdmin/deleteUser", values, data => data.success && typeof(cb) === "function" && cb()),
        addAppToUser: (values, cb) => api("/api/UsersAdmin/addAppToUser", values, data => data.success && typeof(cb) === "function" && cb()),
        saveAppPermissions: (userId, appId, permissions, cb) => api("/api/UsersAdmin/saveAppPermissions", {userId: userId, appId: appId, permissions: permissions}, data => data.success && typeof(cb) === "function" && cb()),
        deleteAppFromUser: (userId, appId, cb) => api("/api/UsersAdmin/deleteAppFromUser", {userId: userId, appId: appId}, data => data.success && typeof(cb) === "function" && cb()),
    }
};

export default Server;