const InputWithLabel = props => <div className={["form-outline", props.className].filter(e => e).join(" ")}>
    <label className="form-label border-label" style={{pointerEvents: "none"}}>{props.label}</label>
    <input
        type={props.type || "text"}
        name={props.name}
        className="form-control border border-primary"
        placeholder={props.placeholder !== undefined ? props.placeholder : props.label}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        autoComplete={props.name}
    />
</div>;
export default InputWithLabel;