const state = {
    events: [],
    topIndex: 0,
    global: {}
};
const CoreTools = {
    state: state.global,
    clone: value => JSON.parse(JSON.stringify(value)),
    log: (...msg) => CoreTools.getQueryParam("debug") && console.log(...msg),
    emit: (name, ...data) => state.events.filter(watcher => watcher.name === name).forEach(watcher => watcher.callback(...data)),
    on: (name, callback) => {
        if (typeof(callback) === "function") {
            let watcher = {name: name, callback: callback};
            state.events.push(watcher);
            return () => CoreTools.remove(state.events, watcher);
        }
    },
    watchHolder: () => {
        let watchers = [];
        return wcb => {
            if (typeof(wcb) === "function") {watchers.push(wcb);}
            else if (wcb === "end") {watchers.filter(w => typeof(w) === "function").forEach(w => w());}
        };
    },
    stateHandler: parent => {
        if (!CoreTools.isObject(parent.state)) {parent.state = {};}
        let svMounted = false;
        return (input, cb) => CoreTools.switch(
            [input === "mount", () => svMounted = true],
            [input === "unmount", () => svMounted = false],
            [svMounted, () => parent.setState(input, cb)],
            [CoreTools.isObject(input), () => {
                Object.keys(input).forEach(key => parent.state[key] = input[key]);
                if (typeof(cb) === "function") {cb();}
            }],
            [typeof(cb) === "function", () => cb()]
        );
    },
    isObject: value => value && typeof(value) === "object" && !Array.isArray(value) ? true : false,
    getQueryParam: (name, source) => {
        const urlSearchParams = new URLSearchParams(source !== undefined ? source : window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        return (params && params[name]) || null;
    },
    getAnchor: () => {
        const url = document.URL;
        const urlParts = url.split("#");
        return urlParts.length > 1 ? urlParts[1] : "";
    },
    asPhone: (source, onInput) => {
        let s2 = typeof(source) === "string" ? (onInput ? source.replace(/\D/g, "").slice(-10) : source.replace(/\D/g, "").slice(-10)) : "";
        let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !m ? s2 : "(" + m[1] + ") " + m[2] + "-" + m[3];
    },
    asString: s => {
        switch (typeof(s)) {
            case "string": return s;
            case "number": return s.toString();
            case "boolean": return s.toString();
            default: return "";
        }
    },
    numberCS: (x, dot) => {
        if (typeof(x) === "string") {x = parseFloat(x.replace(/[^0-9.]/g, '') || 0);}
        else if (typeof(x) !== "number") {x = 0;}
        return x.toFixed(dot !== undefined ? dot : 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    toNumber: x => {
        if (typeof(x) === "string") {x = parseFloat(x.replace(/[^0-9.]/g, '') || 0);}
        else if (typeof(x) !== "number") {x = 0;}
        return x;
    },
    compare: v => {
        if ((["number", "boolean"]).includes(typeof(v))) {v = v.toString();}
        else if (([null, undefined]).includes(v)) {v = "";}
        if (typeof(v) === "string") {
            return v.toLowerCase().replace(/[^a-z0-9]/g, "");
        } else {
            return "";
        }
    },
    first: array => (callback, alternate) => {
        if (Array.isArray(array) && array.length && typeof(callback) === "function") {
            return callback(array[0]);
        } else if ((!Array.isArray(array) || !array.length) && typeof(alternate) === "function") {
            return alternate();
        } else if (Array.isArray(array) && array.length) {
            return array[0];
        } else {
            return null;
        }
    },
    last: array => (callback, alternate) => {
        if (Array.isArray(array) && array.length && typeof(callback) === "function") {
            return callback(array[array.length - 1]);
        } else if ((!Array.isArray(array) || !array.length) && typeof(alternate) === "function") {
            return alternate();
        } else if (Array.isArray(array) && array.length) {
            return array[array.length -1];
        } else {
            return null;
        }
    },
    remove: (objArray, element) => {
        if (objArray && typeof(objArray) === "object") {
            if (Array.isArray(objArray)) {
                let i = objArray.indexOf(element);
                if (i > -1) {objArray.splice(i, 1);}
            } else {
                if (objArray[element] !== undefined) {
                    delete(objArray[element]);
                }
            }
        }
        return objArray;
    },
    upload: (callback, accept, multiple) => {
        if (window.FileReader) {
            let uldr = document.createElement("input");
            uldr.type = "file";
            uldr.accept = typeof(accept) === "string" ? accept : "image/*";
            uldr.multiple = multiple ? true : false;
            uldr.onchange = event => {
                console.log("Upload Event:", event);
                const files = Object.values(event.target.files);
                if (files.length) {
                    let results = [];
                    files.forEach(file => {
                        const readFile = new FileReader();
                        readFile.onload = fData => {
                            results.push({
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                lastModified: file.lastModified,
                                data: fData.target.result
                            });
                            if (results.length === files.length) {
                                callback(true, multiple ? results : results[0]);
                                setTimeout(() => uldr.remove());
                            }
                        };
                        readFile.readAsDataURL(file);
                    });
                } else {
                    callback(false);
                    setTimeout(() => uldr.remove());
                }
            };
            document.body.appendChild(uldr);
            setTimeout(() => {
                uldr.click();
                uldr.style.display = "none";
            });
        } else {
            callback(false);
        }
    },
    move: (pEvent, elem, options, callback) => {
        pEvent.preventDefault();
        let pX = elem.offsetLeft;
        let pY = elem.offsetTop;
        let mX = pEvent.clientX;
        let mY = pEvent.clientY;
        elem.style.zIndex = ++state.topIndex;
        const move = event => {
            if (event.buttons !== 1) {
                window.removeEventListener("mousemove", move);
                CoreTools.constrain(elem, options);
                if (typeof(callback) === "function") {callback(elem.offsetLeft, elem.offsetTop);}
            } else {
                elem.style.left = (pX - mX + event.clientX) + "px";
                elem.style.top = (pY - mY + event.clientY) + "px";
                CoreTools.constrain(elem, options);
            }
        };
        window.addEventListener("mousemove", move);
    },
    constrain: (elem, pOptions) => {
        let options = pOptions && typeof(pOptions) === "object" && !Array.isArray(pOptions) ? pOptions: {};
        if (elem.offsetLeft < (options.left || 0)) {elem.style.left = (options.left || 0) + "px";}
        else if (elem.offsetLeft + elem.offsetWidth > window.innerWidth - (options.right || 0)) {elem.style.left = (window.innerWidth - elem.offsetWidth - (options.right || 0)) + "px";}
        if (elem.offsetTop < (options.top || 0)) {elem.style.top = (options.top || 0) + "px";}
        else if (elem.offsetTop + elem.offsetHeight > window.innerHeight - (options.bottom || 0)) {elem.style.top = (window.innerHeight - elem.offsetHeight - (options.bottom || 0)) + "px";}
    },
    queue: () => {
        let q = [], results = {};
        return {
            add: (name, cb) => q.push({name: name, cb: cb}),
            execute: callback => {
                const next = () => {
                    if (q.length) {
                        let c = q.shift(); 
                        c.cb(r => {
                            if (!c.name || c.name === "collect") {
                                if (!results.collect) {results.collect = [];}
                                results.collect.push(r);
                            } else if (results[c.name] === undefined) {
                                results[c.name] = r;
                            } else if (Array.isArray(results[c.name]) && Array.isArray(r)) {
                                results[c.name].push(...r);
                            } else if (typeof(results[c.name]) === "object" && results[c.name] !== null && typeof(r) === "object" && r !== null && !Array.isArray(results[c.name]) && !Array.isArray(r)) {
                                Object.keys(r).forEach(key => {results[c.name][key] = r[key];});
                            } else {
                                if (!results.collect) {results.collect = [];}
                                results.collect.push(r);
                            }
                            setTimeout(next);
                        });
                    } else {callback(results);}
                };
                next();
            }
        };
    },
    orderBy: (arr, field, descending) => {
        let results = arr.length ? [arr[0]] : [];
        const sortType = value => {
            if (typeof(value) === "string") {return value;}
            else if (typeof(value) === "number") {return value;}
            else if (typeof(value) === "boolean") {return value ? "0" : "1";}
            else {return "";}
        };
        for (let i = 1; i < arr.length; i++) {
            let match = false;
            for (let ii = 0; ii < results.length; ii++) {
                if ((descending && sortType(arr[i][field]) >= sortType(results[ii][field])) || (!descending && sortType(arr[i][field]) <= sortType(results[ii][field]))) {
                    results.splice(ii, 0, arr[i]);
                    match = true;
                    break;
                }
            }
            if (!match) {results.push(arr[i]);}
        }
        return results;
    },
    flatten: obj => {
        let nr = {};
        const sp = or => Object.keys(or).forEach(key => {
            if (["string", "boolean", "number"].includes(typeof(or[key])) || Array.isArray(or[key])) {
                nr[key] = or[key];
            } else if (or[key] && typeof(or[key]) === "object") {
                sp(or[key]);
            }
        });
        sp(obj);
        return nr;
    },
    fuseObj: (...obj) => {
        let results = {};
        obj.forEach(o => {if (o && typeof(o) === "object" && !Array.isArray(o)) {Object.keys(o).forEach(key => {results[key] = o[key];});}});
        return results;
    },
    setTheme: theme => {
        let root = document.documentElement;
        Object.keys(theme || {}).forEach(key => root.style.setProperty(key, theme[key]));
    },
    memory: {
        get: cookieName => {
            let i, x, y, ARRcookies = document.cookie.split(";"), rValue = "";
            for (i = 0; i < ARRcookies.length; i++) {
                x = ARRcookies[i].substring(0, ARRcookies[i].indexOf("="));
                y = ARRcookies[i].substring(ARRcookies[i].indexOf("=") + 1);
                x = x.replace(/^\s+|\s+$/g, ""); 
                if (x === cookieName) {rValue = unescape(y);}
            } 
            return rValue || sessionStorage.getItem(cookieName);
        },
        set: (cookieName, cookieValue, expireInDays) => {
            sessionStorage.setItem(cookieName, cookieValue);
            let expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + (expireInDays || 30));
            document.cookie = `${cookieName}=${escape(cookieValue)}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Lax`;
        }
    },
    switch: (...params) => {
        if (typeof(params[0]) === "object" && Array.isArray(params[0])) {
            for (let i = 0; i < params.length; i++) {
                if (typeof(params[i][0]) === "function" ? params[i][0]() : params[i][0]) {return typeof(params[i][1]) === "function" ? params[i][1]() : params[i][1];}
            }
        } else if (params.length >= 1) {
            if (typeof(params[1]) === "object" && Array.isArray(params[1])) {
                for (let i = 1; i < params.length; i++) {
                    if (typeof(params[i][0]) === "function" ? params[i][0]() === params[0] : params[i][0] === params[0]) {return typeof(params[i][1]) === "function" ? params[i][1]() : params[i][1];}
                }
            } else if (params.length >= 2) {
                for (let i = 2; i < params.length; i++) {
                    if (typeof(params[i][0]) === "function" ? params[i][0]() === params[0] : params[i][0] === params[0]) {return typeof(params[i][1]) === "function" ? params[i][1]() : params[i][1];}
                }
                return params[1];
            }
        }
    },
    importStyle: (href, callback) => {
        let existing = Array.from(document.head.childNodes).find(h => h.tagName === "LINK" && (h.getAttribute("href") || "").split("?")[0] === (href || "").split("?")[0]);
        if (existing) {
            if (typeof(callback) === "function") {setTimeout(() => callback(existing));}
            return existing;
        } else {
            let css = document.createElement("LINK");
            if (typeof(callback) === "function") {
                css.onload = () => setTimeout(() => callback(css));
            };
            css.setAttribute("type", "text/css");
            css.setAttribute("rel", "stylesheet");
            css.setAttribute("href", href);
            document.head.appendChild(css);
            return css;
        }
    },
    importScript: (url, callback, crossorigin, id) => {
        let existing = Array.from(document.head.childNodes).find(h => h.tagName === "SCRIPT" && (h.getAttribute("src") || "").split("?")[0] === (url || "").split("?")[0]);
        if (existing) {
            if (typeof(callback) === "function") {setTimeout(() => callback(existing), 250);}
            return existing;
        } else {
            let script = document.createElement("script");
            if (typeof(callback) === "function") {
                script.onload = () => setTimeout(() => callback(script), 250);
            };
            script.setAttribute("type", "text/javascript");
            if (crossorigin) {script.setAttribute("crossorigin", "*");}
            if (id) {script.setAttribute("id", id);}
            script.setAttribute("src", url);
            document.head.appendChild(script);
            return script;
        }
    },
    timeStamp: () => Date.now(),
    match: (str, regex) => {
        const match = typeof(str) === "string" ? str.match(regex) : null;
        return match !== null && str === match.join("");
    },
    fromForm: callback => event => {
        event.preventDefault();
        let formSet = {};
        [
            ...Array.from(event.target.getElementsByTagName("input")).map(i => CoreTools.switch(
                [i.type === "checkbox", () => ({name: i.name, value: i.checked ? true : false})],
                [i.type === "radio", () => i.checked ? {name: i.name, value: i.value} : null],
                [i.type === "number" || i.subtype === "number", () => ({name: i.name, value: [null, ""].includes(i.value) ? null : (Number(i.value) || 0)})],
                [true, () => ({name: i.name, value: typeof(i.value) === "string" ? i.value.trim() : i.value})]
            )),
            ...Array.from(event.target.getElementsByTagName("select")).map(i => ({name: i.name, value: i.getAttribute("subtype") === "number" ? (Number(i.value) || 0) : i.value})),
            ...Array.from(event.target.getElementsByTagName("textarea")).map(i => ({name: i.name, value: i.value}))
        ].filter(i => CoreTools.isObject(i) && i.name).forEach(i => formSet[i.name] = i.value);
        if (typeof(callback) === "function") {callback(formSet);}
        else {return formSet;}
    }
};
export default CoreTools;