import ByWidth from "./ByWidth";

const TitleBanner = () => <div className="bg-white">
  <ByWidth onWidth={w => w > 768}>
    <div className="container-lg" style={{height: "80px"}}>
      <div className="row">
        <div className="col-sm-6" style={{verticalAlign: "middle", lineHeight: "80px"}}>
          <img src={window.appSettings.logo.img} style={window.appSettings.logo.style} alt={window.appSettings.logo.alt} />
        </div>
        <div className="col-sm-6 text-end" style={{verticalAlign: "middle", lineHeight: "80px"}}>
          <span style={window.appSettings.title.style}>{window.appSettings.title.short}</span>
        </div>
      </div>
    </div>
  </ByWidth>
  <ByWidth onWidth={w => w <= 768 && w > 500}>
    <div className="text-center" style={{height: "80px", verticalAlign: "middle", lineHeight: "80px"}}>
      <span style={window.appSettings.title.style}>{window.appSettings.title.long}</span>
    </div>
  </ByWidth>
  <ByWidth onWidth={w => w <= 500}>
    <div className="text-center" style={{height: "80px", verticalAlign: "middle", lineHeight: "80px"}}>
      <span style={window.appSettings.title.style}>{window.appSettings.title.short}</span>
    </div>
  </ByWidth>
</div>;

export default TitleBanner;