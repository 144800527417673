import React from "react";
import CoreTools from "../services/CoreTools";
const watchers = [];
window.addEventListener("resize", event => watchers.forEach(w => w.cb(event.target.innerWidth)));
const ByWidth = props => {
    const [visible, setVisible] = React.useState(typeof(props.onWidth) === "function" && props.onWidth(window.innerWidth) ? true : false);
    React.useEffect(() => {
        const watch = {cb: nw => setVisible(typeof(props.onWidth) === "function" && props.onWidth(nw) ? true : false)};
        watchers.push(watch);
        return () => CoreTools.remove(watchers, watch);
    }, [setVisible, props]);
    return visible && props.children;
};
export default ByWidth;