import React from "react";
import Server from "../../services/Server";
import CoreTools from "../../shared/services/CoreTools";

const ActiveSessions = () => {    
    const [activeSessions, setActiveSessions] = React.useState([]);
    
    React.useEffect(() => Server.User.activeSessions(setActiveSessions), [setActiveSessions]);
    const revokeSession = id => Server.User.logout(id, () => Server.User.activeSessions(setActiveSessions));
    
    return <>
        <div className="mt-2">
            <div className="container-lg p-2 bg-white rounded-3">
                <h4 className="text-primary">Active Sessions</h4>
            </div>
            {activeSessions.map(as => <div key={as.id} className="container-lg bg-white rounded-3 mt-2 p-2">
                <div className="row">
                    <div className="col-3 text-end text-nowrap">Session ID:</div>
                    <div className="col-sm-9">{as.id} {as.current && " (Current Session)"}</div>
                </div>
                <div className="row">
                    <div className="col-3 text-end text-nowrap">Session Name:</div>
                    <div className="col-sm-9">{as.name || "Not Assigned"}</div>
                </div>
                <div className="row">
                    <div className="col-3 text-end text-nowrap">IP Address:</div>
                    <div className="col-sm-9">{as.ip}</div>
                </div>
                <div className="row">
                    <div className="col-3 text-end text-nowrap">User Agent:</div>
                    <div className="col-sm-9">{as.agent}</div>
                </div>
                <div className="row">
                    <div className="col-3 text-end text-nowrap">Expires In:</div>
                    <div className="col-sm-9">{CoreTools.switch(
                        [as.expiresIn === 0, `Less then an hour`],
                        [as.expiresIn === 1, `1 hour`],
                        [as.expiresIn < 0, `Expired`],
                        [true, () => `${as.expiresIn} Hours`]
                    )}</div>
                </div>
                {
                    as.current ? 
                    <div className="text-end"><button type="button" className="btn btn-sm btn-secondary bg-gradient" disabled={true}><i className="fa-solid fa-arrow-right-from-bracket"></i> Current Session</button></div> : 
                    <div className="text-end"><button type="button" className="btn btn-sm btn-danger bg-gradient" onClick={() => revokeSession(as.id)}><i className="fa-solid fa-arrow-right-from-bracket"></i> Revoke Session</button></div>
                }
            </div>)}
        </div>
    </>;
};
export default ActiveSessions;