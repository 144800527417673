import React from "react";
import Server from "../../services/Server";
import CoreTools from "../../shared/services/CoreTools";
import Modal from "../../shared/directives/Modal";
import InputWithLabel from "../../directives/InputWithLabel";

const DeleteUserModal = props => {
    const confirmDeleteUser = () => Server.UsersAdmin.deleteUser({id: props.data.id}, () => props.close(true));
    const cancelDeleteUser = () => props.close(false);
    return <div>
        <div>
            <h5>{props.data.title}</h5>
        </div>
        <div className="float-end">
            <div className="input-group mt-4">
                <button className="btn btn-secondary bg-gradient" type="button" style={{minWidth: "120px"}} onClick={cancelDeleteUser}>Cancel</button>
                <button className="btn btn-danger bg-gradient" type="button" style={{minWidth: "120px"}} onClick={confirmDeleteUser}>Delete</button>
            </div>
        </div>
    </div>;
};

const ManageUserModal = props => {
    const [data, setData] = React.useState(CoreTools.isObject(props.data) ? props.data : {});
    const updateData = obj => setData(Object.assign({}, data, obj));

    const deleteUser = () => Modal.Load(
        <DeleteUserModal />,
        {title: "Confirm Delete User", data: props.data},
        success => success && props.close(true)
    );

    return <form onSubmit={CoreTools.fromForm(values => Server.UsersAdmin.saveUser(Object.assign({id: data.id || null}, values), () => props.close(true)))}>
        <div className="text-end">
            <label className="form-check-label">
                <input className="form-check-input" style={{marginRight: "5px"}} type="checkbox" name="enabled" defaultChecked={data.enabled} onChange={e => updateData({enabled: e.target.checked})} />
                Enabled
            </label>
        </div>
        <div className="row mt-1">
            <div className="col-12">
                <InputWithLabel type="text" label="Firstname" name="firstname" placeholder="Ex: John" defaultValue={data.firstname || ""} />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <InputWithLabel type="text" label="Lastname" name="lastname" placeholder="Ex: Doe" defaultValue={data.lastname || ""} />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <InputWithLabel type="text" label="E-mail Address" name="email" placeholder="Ex: john.doe@example.com" defaultValue={data.email || ""} />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <InputWithLabel type="text" label="Mobile Number" name="phone" placeholder="Ex: 5554567890" defaultValue={data.phone || ""} />
            </div>
        </div>
        <div className="float-end">
            <div className="input-group mt-4">
                {data.id && <button className="btn btn-danger bg-gradient" type="button" style={{minWidth: "120px"}} onClick={deleteUser}>Delete</button>}
                <button className="btn btn-secondary bg-gradient" type="button" style={{minWidth: "120px"}} onClick={() => props.close(false)}>Cancel</button>
                <button className="btn btn-primary bg-gradient" type="submit" style={{minWidth: "120px"}}>Save</button>
            </div>
        </div>
    </form>;
};

const DeleteAppFromUser = props => {
    const confirmDeleteApp = () => Server.UsersAdmin.deleteAppFromUser(props.user.id, props.app.id, () => props.close(true));
    const cancelDeleteApp = () => props.close(false);
    return <div>
        <div>
            Confirm delete of '{props.app.name}' from '{props.user.firstname} {props.user.lastname}'.
        </div>
        <div className="float-end">
            <div className="input-group mt-4">
                <button className="btn btn-secondary bg-gradient" type="button" style={{minWidth: "120px"}} onClick={cancelDeleteApp}>Cancel</button>
                <button className="btn btn-danger bg-gradient" type="button" style={{minWidth: "120px"}} onClick={confirmDeleteApp}>Delete</button>
            </div>
        </div>
    </div>;
};

const AddApp = props => {
    const [apps, setApps] = React.useState([]);
    React.useEffect(() => Server.AppsAdmin.listApps(setApps), [setApps]);
    const selectApp = app => {
        Server.UsersAdmin.addAppToUser({appId: app.id, userId: props.userId}, () => props.close(true));
    };
    return <div>
        <div class="d-grid gap-2">
            {apps.filter(a => !props.apps.find(ua => ua.id === a.id)).map(a => <button key={a.id} className="btn btn-fluid btn-primary bg-gradient" onClick={() => selectApp(a)}>{a.title} ({a.name})</button>)}
        </div>
        <div className="float-end">
            <div className="input-group mt-4">
                <button className="btn btn-secondary bg-gradient" type="button" style={{minWidth: "120px"}} onClick={() => props.close(false)}>Cancel</button>
            </div>
        </div>
    </div>;
};

const ManageApplication = props => {
    const [app, setApp] = React.useState({});
    const appPermissions = ((props.user.apps || []).find(a => a.id === props.appId) || {permissions: []}).permissions;
    React.useEffect(() => Server.AppsAdmin.getAppById(props.appId, setApp), [setApp, props.appId]);

    const deleteAppFromUser = () => Modal.Load(
        <DeleteAppFromUser />,
        {title: "Delete App", user: props.user, app: app},
        success => success && props.close(true)
    );

    const savePermissions = values => Server.UsersAdmin.saveAppPermissions(props.user?.id, props.appId, Object.keys(values).filter(key => values[key]), () => props.close(true));
    
    return <form onSubmit={CoreTools.fromForm(savePermissions)}>
        <div className="float-end">
            <div>{app.title} ({app.name})</div>
            <div className="mt-2">
                {Array.isArray(app.permissions) && app.permissions.map(p => <div key={p}>
                    <label className="form-check-label pointer">
                        <input className="form-check-input" style={{marginRight: "5px"}} type="checkbox" name={p} defaultChecked={appPermissions.includes(p)} />
                        {p}
                    </label>
                </div>)}
            </div>
            <div className="input-group mt-4">
                <button className="btn btn-danger bg-gradient" type="button" style={{minWidth: "120px"}} onClick={deleteAppFromUser}>Delete</button>
                <button className="btn btn-secondary bg-gradient" type="button" style={{minWidth: "120px"}} onClick={() => props.close(false)}>Cancel</button>
                <button className="btn btn-primary bg-gradient" type="submit" style={{minWidth: "120px"}}>Save</button>
            </div>
        </div>
    </form>
};

const Users = () => {
    const [users, setUsers] = React.useState([]);
    React.useEffect(() => Server.UsersAdmin.listUsers(setUsers), [setUsers]);

    const addUser = () => Modal.Load(
        <ManageUserModal />,
        {title: "Add User"},
        refresh => refresh && Server.UsersAdmin.listUsers(setUsers)
    );

    const editUser = user => Modal.Load(
        <ManageUserModal />,
        {title: "Edit User", data: user},
        refresh => refresh && Server.UsersAdmin.listUsers(setUsers)
    );

    const addApp = user => Modal.Load(
        <AddApp />,
        {title: "Add App", userId: user.id, apps: user.apps},
        refresh => refresh && Server.UsersAdmin.listUsers(setUsers)
    );

    const manageApplication = (user, appId) => Modal.Load(
        <ManageApplication />,
        {title: "Manage Application", user: user, appId: appId},
        refresh => refresh && Server.UsersAdmin.listUsers(setUsers)
    );

    return <>
        <div className="container-lg mt-2 bg-white p-2 rounded-3">
            <div className="float-end"><button className="btn btn-sm btn-primary btn-150 bg-gradient" onClick={() => addUser()}><i className="fa-solid fa-plus"></i> Add User</button></div>
            <h3 className="text-primary">Users</h3>
        </div>
        {users.map(user => <div key={user.id} className="container-lg mt-2 bg-white p-2 rounded-3">
            <div className="float-end"><button className="btn btn-sm btn-primary btn-150 bg-gradient"onClick={() => editUser(user)}><i className="fa-solid fa-pen-to-square"></i> Manage</button></div>
            <h4>{user.firstname} {user.lastname}</h4>
            <div className="row">
                <div className="col-md-6">
                    <div>E-mail Address: {user.email}</div>
                    <div>Mobile Phone: {CoreTools.asPhone(user.phone)}</div>
                    <div>Enabled: {user.enabled ? <span className="badge bg-success">Yes</span> : <span className="badge bg-danger">No</span>}</div>
                </div>
                <div className="col-md-6">
                    <div>Apps:<span className="badge bg-info bg-gradient pointer" title="Add Application" onClick={() => addApp(user)} style={{marginLeft: "5px"}}><i className="fa-solid fa-plus"></i></span></div>
                    {user.apps.map(a => <div key={a.name} style={{marginLeft: "5px"}}>
                        <span className="badge bg-primary">{a.name}</span>
                        {(user.apps.find(ua => ua.id === a.id) || {permissions: []}).permissions.map(p => <span key={p} className="badge bg-secondary" style={{marginLeft: "5px"}}>{p}</span>)}
                        <span className="badge bg-info bg-gradient pointer" title="Manage Application" onClick={() => manageApplication(user, a.id)} style={{marginLeft: "5px"}}><i className="fa-solid fa-pen-to-square"></i></span>
                    </div>)}
                </div>
            </div>
        </div>)}
    </>;
};
export default Users;