import React from "react";
import InputWithLabel from "../../directives/InputWithLabel";
import Server from "../../services/Server";
import Notify from "../../shared/directives/Notify";
import CoreTools from "../../shared/services/CoreTools";
import Navigate from "../../shared/services/Navigate";

const TitleText = props => <div className="form-outline mb-4"><h3 className="text-primary">{props.title || props.children}</h3></div>;
const SubmitButton = props => <button type="submit" className="btn btn-primary btn-block mt-3">{props.children}</button>;

const ApiRootPanel = () => <form
    onSubmit={CoreTools.fromForm(Server.test)}
    className="card card-body epanal-signin"
>
    <TitleText>Set Login Api Root</TitleText>
    <Notify.SingleMessage className="form-outline mb-4" />
    <InputWithLabel type="text" label="API Root Path" placeholder="Ex: https://api.example.com" name="apiRootPath" />
    <SubmitButton>Set Path</SubmitButton>
</form>;

const LoginPanel = () => <form 
    onSubmit={CoreTools.fromForm(Server.Guest.login)}
    className="card card-body epanal-signin"
>
    <TitleText>Sign In</TitleText>
    <Notify.SingleMessage className="form-outline mb-4" />
    <InputWithLabel type="text" label="E-mail Address" name="email" />
    <InputWithLabel className="mt-3" type="password" label="Password" name="password" />
    <SubmitButton>Sign in</SubmitButton>
    <div className="row mt-2">
        <div className="col d-flex justify-content-center">
            <div className="form-check">
                <input className="form-check-input" type="checkbox" name="rememberMe" id="form2Example31" defaultChecked />
                <label className="form-check-label" htmlFor="form2Example31"> Remember me </label>
            </div>
        </div>
        <div className="col text-center">
            <a className="link-primary pointer" href="#NewPassword">Forgot password?</a>
        </div>
    </div>
</form>;

const NewPassword = () => <form 
    onSubmit={CoreTools.fromForm(Server.Guest.requestPasswordReset)}
    className="card card-body epanal-signin"
>
    <TitleText>Set New Password</TitleText>
    <InputWithLabel type="text" label="E-mail Address" name="email" />
    <InputWithLabel className="mt-3" type="text" label="Mobile Number" name="phone" />
    <SubmitButton>Request Password Reset</SubmitButton>
    <div className="text-center mt-2">
        <a className="link-primary pointer" href="#Login">Return To Sign In</a>
    </div>
</form>;

const SavePassword = () => <form 
    onSubmit={CoreTools.fromForm(Server.Guest.submitPasswordReset)}
    className="card card-body epanal-signin"
>
    <TitleText>Set New Password</TitleText>
    <InputWithLabel type="text" label="E-mail Address" name="email" />
    <InputWithLabel className="mt-3" type="text" label="Pin From SMS" name="pin" />
    <InputWithLabel className="mt-3" type="password" label="Password" name="password" />
    <InputWithLabel className="mt-3" type="password" label="Confirm Password" name="confirm" />
    <InputWithLabel className="mt-3" type="text" label="Session Name" placeholder="My Office PC" name="name" />
    <SubmitButton>Save Password</SubmitButton>
    <div className="row mt-2">
        <div className="col-md-6 text-center">
            <span className="link-primary pointer" onClick={() => Server.Guest.resendPin()}>Resend Pin</span>
        </div>
        <div className="col-md-6 text-center">
            <a className="link-primary pointer" href="#Login">Return To Sign In</a>
        </div>
    </div>
</form>;

const MfaValidation = () => <form 
    onSubmit={CoreTools.fromForm(Server.Guest.submitMfa)}
    className="card card-body epanal-signin"
>
    <TitleText>SMS Validation Pin</TitleText>
    <InputWithLabel type="text" label="10 Digit SMS Pin" name="pin" />
    <InputWithLabel className="mt-3" type="text" label="Session Name" placeholder="My Office PC" name="name" />
    <SubmitButton>Submit Pin</SubmitButton>
    <div className="row mt-2">
        <div className="col-md-6 text-center">
            <span className="link-primary pointer" onClick={() => Server.Guest.resendPin()}>Resend Pin</span>
        </div>
        <div className="col-md-6 text-center">
            <a className="link-primary pointer" href="#Login">Return To Sign In</a>
        </div>
    </div>
</form>;

const Login = () => {
    const nav = Navigate();
    const [hash, setHash] = React.useState(nav.get());
    React.useEffect(() => nav.watch(setHash), [nav, setHash]);
    const [apiDomain, setApiDomain] = React.useState(Server.getApiHost());
    React.useEffect(() => Server.watchApiHost(setApiDomain), [setApiDomain]);
    return CoreTools.switch(
        [() => apiDomain === "localhost",       () => <ApiRootPanel />],
        [hash === "#NewPassword",               () => <NewPassword />],
        [hash === "#SavePassword",              () => <SavePassword />],
        [hash === "#MfaValidation",             () => <MfaValidation />],
        [true,                                  () => <LoginPanel />]
    );
};
export default Login;