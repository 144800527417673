import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
Object.assign(document.body.style, {
    "background": `url('${window.appSettings.backgroundUrl}') no-repeat center center fixed`,
    "background-size": "cover",
    "-webkit-background-size": "cover",
    "-moz-background-size": "cover",
    "-o-background-size": "cover"
});
document.title = window.appSettings.title.long;
ReactDOM.createRoot(document.getElementById('root')).render(<App />);