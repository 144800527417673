import React from "react";
import Server from "../../services/Server";
import Navigate from "../../shared/services/Navigate";

const MyApps = () => {
    const nav = Navigate();
    const [apps, setApps] = React.useState([]);
    React.useEffect(() => Server.User.myApps(setApps), [setApps]);
    return <>
        <div className="container-lg mt-2 bg-white p-2 rounded-3">
            <h3 className="text-primary">My Apps</h3>
        </div>
        <div className="container-lg mt-1">
            <div className="row">
                {apps.map(app => <div key={app.id} className="col-lg-6 p-1">
                    <div className="card bg-white rounded-3">
                        <div className="card-body">
                            <h5>{app.title}</h5>
                            <p>{app.description}</p>
                            <div className="text-end">
                                <button className="btn btn-primary btn-sm bg-gradient" style={{minWidth: "120px"}} onClick={() => nav.set(app.url)} disabled={!app.url}>Go</button>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </>;
};
export default MyApps;