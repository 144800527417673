import React from "react";
import Layout from "./screens/Layout/Layout";
import Login from "./screens/Login/Login";
import Server from "./services/Server";
import TitleBanner from "./shared/directives/TitleBanner";
import CoreTools from "./shared/services/CoreTools";



const App = () => {
  const [session, setSession] = React.useState(Server.getSession());
  React.useEffect(() => Server.watchSessionUpdate(setSession), [setSession]);
  return <div className="align-items-center">
    <TitleBanner title="Authentication Manager" />
    {
      CoreTools.switch(
        [() => session.valid && session.mfa, () => <Layout />],
        [true, <Login />]
      )
    }
  </div>;
};

export default App;
