import React from "react";
import Server from "../../services/Server";
import CoreTools from "../../shared/services/CoreTools";
import Modal from "../../shared/directives/Modal";
import InputWithLabel from "../../directives/InputWithLabel";

const DeleteAppModal = props => {
    const confirmDeleteApp = () => Server.AppsAdmin.deleteApp({id: props.data.id}, () => props.close(true));
    const cancelDeleteApp = () => props.close(false);
    return <div>
        <div>
            <h5>{props.data.title}</h5>
        </div>
        <div className="float-end">
            <div className="input-group mt-4 text-nowrap">
                <button className="btn btn-secondary bg-gradient" type="button" style={{minWidth: "120px"}} onClick={cancelDeleteApp}>Cancel</button>
                <button className="btn btn-danger bg-gradient" type="button" style={{minWidth: "120px"}} onClick={confirmDeleteApp}>Delete</button>
            </div>
        </div>
    </div>;
};

const ManageAppModal = props => {
    const [data, setData] = React.useState(CoreTools.isObject(props.data) ? props.data : {});
    const updateData = obj => setData(Object.assign({}, data, obj));

    const deleteApp = () => Modal.Load(
        <DeleteAppModal />,
        {title: "Confirm Delete App", data: {id: data.id, title: data.title}},
        success => success && props.close(true)
    );

    return <form onSubmit={CoreTools.fromForm(values => Server.AppsAdmin.saveApp(Object.assign({id: data.id || null}, values), () => props.close(true)))}>
        <div className="text-end">
            <label className="form-check-label">
                <input className="form-check-input" style={{marginRight: "5px"}} type="checkbox" name="enabled" defaultChecked={data.enabled} onChange={e => updateData({enabled: e.target.checked})} />
                Enabled
            </label>
        </div>
        <div className="row mt-1">
            <div className="col-12">
                <InputWithLabel type="text" label="App Title" name="title" placeholder="Ex: My App" defaultValue={data.title || ""} />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <InputWithLabel type="text" label="Description" name="description" placeholder="Ex: My App is good!" defaultValue={data.description || ""} />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <InputWithLabel type="text" label="Name" name="name" placeholder="Ex: myapp" defaultValue={data.name || ""} />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <InputWithLabel type="text" label="Permissions (CSV)" name="permissions" placeholder="Ex: RouteAdmin, AssetAdmin, UserAdmin" defaultValue={Array.isArray(data.permissions) ? data.permissions.join(", ") : ""} />
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <InputWithLabel type="text" label="Redirect Url" name="url" placeholder="Ex: https://myapp.consumergenius.com/" defaultValue={data.url || ""} />
            </div>
        </div>
        <div className="float-end">
            <div className="input-group mt-4 text-nowrap">
                {data.id && <button className="btn btn-danger bg-gradient" type="button" style={{minWidth: "120px"}} onClick={deleteApp}>Delete</button>}
                <button className="btn btn-secondary bg-gradient" type="button" style={{minWidth: "120px"}} onClick={() => props.close(false)}>Cancel</button>
                <button className="btn btn-primary bg-gradient" type="submit" style={{minWidth: "120px"}}>Save</button>
            </div>
        </div>
    </form>;
};

const Apps = () => {
    const [apps, setApps] = React.useState([]);
    React.useEffect(() => Server.AppsAdmin.listApps(setApps), [setApps]);

    const addApp = () => Modal.Load(
        <ManageAppModal />,
        {title: "Add App"},
        refresh => refresh && Server.AppsAdmin.listApps(setApps)
    );

    const editApp = app => Modal.Load(
        <ManageAppModal />,
        {title: "Edit App", data: app},
        refresh => refresh && Server.AppsAdmin.listApps(setApps)
    );

    return <>
        <div className="container-lg mt-2 bg-white p-2 rounded-3">
            <div className="float-end"><button className="btn btn-sm btn-primary btn-150 bg-gradient" onClick={() => addApp()}><i className="fa-solid fa-plus"></i> Add Application</button></div>
            <h3 className="text-primary">Apps</h3>
        </div>
        {apps.map(app => <div key={app.id} className="container-lg mt-2 bg-white p-2 rounded-3">
            <div className="float-end mt-1">
                <div className="input-group">
                    <button className="btn btn-sm btn-primary btn-120 bg-gradient" onClick={() => editApp(app)}><i className="fa-solid fa-pen-to-square"></i> Manage</button>
                </div>
            </div>
            <h4>{app.title}</h4>
            <div>{app.description}</div>
            <div>Name: {app.name}</div>
            <div>API Key: {app.apiKey}</div>
            {app.url && <div>Url: {app.url}</div>}
            <div>Enabled: {app.enabled ? <span className="badge bg-success">Yes</span> : <span className="badge bg-danger">No</span>}</div>
            <div>
                Permissions:{app.permissions.map(p => <span key={p} className="badge bg-secondary" style={{marginLeft: "5px"}}>{p}</span>)}
            </div>
            <div>
                Users:{app.users.map(u => <span key={u} className="badge bg-info" style={{marginLeft: "5px"}}>{u}</span>)}
            </div>
        </div>)}
    </>;
};
export default Apps;