import React from "react";
import Modal from "../../shared/directives/Modal";
import CoreTools from "../../shared/services/CoreTools";
import Navigate from "../../shared/services/Navigate";
import Apps from "../Apps/Apps";
import MyApps from "../MyApps/MyApps";
import MyProfile from "../MyProfile/MyProfile";
import Users from "../Users/Users";
import Header from "./Header";


const Layout = props => {
    const nav = Navigate();
    const [hash, setHash] = React.useState(nav.get());
    React.useEffect(() => nav.watch(setHash), [nav, setHash]);

    return <div className="p-1">
        <Header />
        {CoreTools.switch(
            [hash === "#MyProfile", () => <MyProfile />],
            [hash === "#Apps", () => <Apps />],
            // [hash === "#Certificates", () => <Certificates />],
            // [hash === "#Routing", () => <Routes />],
            // [hash === "#Reports", () => <Reports />],
            [hash === "#Users", () => <Users />],
            // [hash === "#Settings", () => <Settings />],
            [true, () => <MyApps />]
        )}
        <Modal.Parent />
    </div>;
};

export default Layout;