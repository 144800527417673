import React from "react";
import Menu from "../../services/Menu";
import Navigate from "../../shared/services/Navigate";


const Header = props => {
    const nav = Navigate();
    const menu = Menu();
    const [hash, setHash] = React.useState(nav.get());
    React.useEffect(() => nav.watch(setHash), [nav, setHash]);

    return <div className="text-center">
        <div className="btn-group menu-group mt-1">
            {menu.mainMenu(hash).map(m => <button type="button" key={m[1]} onClick={() => nav.set(m[1])} className={`btn btn-${m[2] ? "primary" : "secondary"} btn-sm bg-gradient`}>{m[0]}</button>)}
        </div>
    </div>;
};

export default Header;